import { useUserStore } from '@/stores/user';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { query } = to;

  const userStore = useUserStore();
  const redirect = query.redirect === 'true';
  await userStore.fetchUser(redirect);

  if (!userStore.loggedIn && to.path !== '/') {
    return navigateTo('/login');
  }
});
